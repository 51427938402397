<template lang="html">
  <div class="">
    <img src="@/assets/logonewest.svg" alt="" id="logo">
  </div>
  <div class="header">
    <h1>Procena Kandidata</h1>
  </div>

  <div class="form-group">
    <label for="collegue">Kandidat za koga se vrši procena:</label>
    <select name="collegue" id="collegue" v-model="podredjeniName">
       <option  v-for="podr in usersSameCompany" v-bind:key="podr.name">{{podr.name}}</option>
    </select>
    </div>


<div class="tallest">


    <div v-for="(usr,i) in usersSameCompany" :key="i" class="">
        <ProcenaKandidata :user="usr" :noPdf="true" v-if="podredjeniName==usr.name"> </ProcenaKandidata>
    </div>


</div>








</template>

<script>
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
import ProcenaKandidata from '@/components/ProcenaKandidataComponent.vue'
export default {
  components:{
    ProcenaKandidata
  },
    computed: mapGetters(['user','users','usersSameCompany']),
    data(){
      return{
        podredjeni:[],
        podredjeniName:'',
        podredjeniCeo:null,
      }
    },
    methods:{
      ...mapActions(['getProfile','getCompanies','getCollegues']),
      // onChangePodredjeni(event){
      //   //this.podredjeniName=event;
      //   // this.podredjeniCeo=null;
      //   // for (var i = 0; i < this.usersSameCompany.length; i++) {
      //   //   if(this.usersSameCompany[i].name == event.target.value){
      //   //     this.podredjeniCeo=this.usersSameCompany[i];
      //   //   }
      //   // }
      // },
    },
    async created(){

      await this.getProfile();
      await this.getCollegues(this.user.type);



    },
}
</script>
<style>
.tallest{
  min-height:1000px;
}
</style>
